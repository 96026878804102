<template>
  <!-- 待评价课程 -->
  <div class="toEvaluated">
    <van-nav-bar :title="$t('toEvaluated.evaluateTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <van-search v-model="value"
                shape="round"
                background="#fff"
                :placeholder="$t('toEvaluated.evaluateTxt2')"
                @search="onSearch" />
    <van-list v-model="loading"
              :finished="finished"
              :finished-text="$t('toEvaluated.evaluateTxt3')"
              @load="onLoad">
      <van-cell v-for="(item,key) in list"
                :key="key">
        <div class="left">
          <img v-if="item.csurl != ''"
               v-lazy="item.csurl"
               alt="" />
          <img v-else
               src="@/assets/img/default_bgimg.jpg"
               alt="" />
        </div>
        <div class="right">
          <div class="top">
            <span class="name">{{ item.coursename }}</span>
            <span class="score">{{ item.coursecredit }}{{$t('toEvaluated.evaluateTxt8')}}</span>
          </div>
          <div class="pjButton"
               v-if="item.ispost_cs || item.ispost_tea">
            <div class="flex-left"></div>
            <div class="flex-right"
                 :class="item.ispost_tea == true && !item.ispost_cs ?'flexend':''">
              <img src="@assets/img/toExamined-end.png"
                   @click="evaCourse(item,key)"
                   v-if="item.ispost_cs">
              <img src="@assets/img/toExamined-end.png"
                   @click="lecturerCourse(item,key)"
                   v-if="item.ispost_tea">
            </div>
          </div>
          <div class="bottom">
            <p>
              <span>{{$t('toEvaluated.evaluateTxt4')}}</span>
              <span>{{ item.teacher_empname }}</span>
            </p>
            <div class="btn">
              <van-button type="info"
                          @click="evaCourse(item,key)">{{$t('toEvaluated.evaluateTxt5')}}</van-button>
              <van-button style="background:#FBA433;color:#fff"
                          @click="lecturerCourse(item,key)">{{$t('toEvaluated.evaluateTxt6')}}</van-button>
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { getPjCourseList } from '@api/wxpx.js'
import { searchFunc } from '@/utils';
import { Toast } from 'vant';
export default {
  data () {
    return {
      value: "",
      list: [],
      loading: false,
      finished: false,
      userInfo,
      nolist: [],
      form: {
        coursename: "",
        teacher_empname: ""
      }
    };
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home");
    },
    onClickRight () { },
    onSearch () {
      var arr = searchFunc(this.value, this.form, this.nolist);
      if (arr.length > 0)
      {
        this.list = arr;
        this.value = "";
      } else
      {
        Toast(this.$t('toEvaluated.evaluateTxt7'));
      }
    },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // 异步更新数据
      getPjCourseList({ empid: this.userInfo.empid }).then(res => {
        // console.log(res.data)
        if (res.iserror == 0)
        {
          this.list = res.data;
          this.nolist = this.list;
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.error(err);
      })
    },
    // 评价课程
    evaCourse (item, key) {
      if (item.ispost_cs)
      {
        this.$router.push({
          path: "/kcpjtjResult",
          query: {
            iflag: 0,
            mautoid: item.autoid,
            paperid: item.csinvestid ? item.csinvestid : -1
          }
        });
      } else
      {
        this.$router.push({
          path: "/questionnaire",
          query: {
            iflag: 0,
            mautoid: item.autoid,
            paperid: item.csinvestid ? item.csinvestid : -1
          }
        })
      }
    },
    // 讲师评价
    lecturerCourse (item, key) {
      if (item.ispost_tea)
      {
        this.$router.push({
          path: "/kcpjtjResult",
          query: {
            iflag: 1,
            mautoid: item.autoid,
            paperid: item.teainvestid ? item.teainvestid : -1
          }
        });
      } else
      {
        this.$router.push({
          path: "/questionnaire",
          query: {
            iflag: 1,
            mautoid: item.autoid,
            paperid: item.teainvestid ? item.teainvestid : -1
          }
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.toEvaluated {
  background: #fff;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-list {
    .van-cell {
      padding: 42px 30px;
      .van-cell__value {
        display: flex;
        overflow: visible;
        .left {
          position: relative;
          width: 3.89333rem;
          height: 2.8rem;
          overflow: visible;
          background: #ecf2ff;
          opacity: 1;
          border-radius: 0.10667rem;
          text-align: center;
          margin-right: 0.53333rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            display: block;
            max-height: 100% !important;
          }
        }
        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            position: relative;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              font-size: 32px;
              font-family: Source Han Sans CN;
              color: #333;
              display: block;
              max-width: 80%;
            }
            .score {
              color: #2b8df0;
            }
          }
          .bottom {
            p {
              margin: 0;
              font-size: 28px;
              font-family: Source Han Sans CN;
              color: #333;
            }
            .btn {
              display: flex;
              justify-content: space-between;
              .van-button {
                width: 164px;
                height: 68px;
                padding: 0;
                .van-button__content {
                  font-size: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.pjButton {
  width: 100%;
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 9999;
  display: flex;
}
.flex-left {
  width: 3.89333rem;
  margin-right: 0.53333rem;
}
.flex-right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 0;
  &.flexend {
    justify-content: flex-end !important;
  }
  img {
    width: 1.2rem;
  }
}
</style>
